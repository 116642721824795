import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
  }

  body {
    font-family: 'Roboto', sans-serif;
  }

  #root {
    min-height: 100%;
    min-width: 100%;
  }

  p,
  label {
    font-family: 'Roboto', sans-serif!important;
    line-height: 1.5em;
    font-size: 15px;
  }

  input, select {
    font-family: inherit;
    font-size: inherit;
  }

  select.form-control.is-invalid{
    border: 1px solid #dc3545 !important;
  }

  .upload-container {
    margin: 10px 0px;
    text-align: center!important;
    margin-bottom: 20px;
  }
  .tags-container {
    background-color: #fff;
    display: inline-block;
    padding-bottom: 10px;
    color: #555;
    vertical-align: middle;
    border-radius: 4px;
    max-width: 100%;
    line-height: 22px;
    cursor: text;
  }
  .tags-container .tag {
    margin-right: 2px;
    color: white;
  }
  .tags-container .label-info {
    background-color: #5bc0de;
  }
  .tags-container .label {
    display: inline;
    padding: 0.2em 0.6em 0.3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
  }
  .tags-container .tag [data-role="remove"] {
    margin-left: 8px;
    cursor: pointer;
  }
  .tags-container .tag [data-role="remove"]:after {
    content: "x";
    padding: 0px 2px;
  }
  .switch.btn-lg {
    min-width: 5rem;
    line-height: 1.5;
    min-height: calc(1.5em + 1rem + 11px)!important;
  }
  .switch-group .switch-on {
    font-weight: 300!important;
    color: #000000!important;
  }
  .switch-group .switch-off {
    font-weight: 300!important;
  }
  button.mobile {
    display: none!important;
  }
  .navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini img {
    width: calc(70px - 25px);
  }
  .custom-select__control {
    min-height: 50px!important;
  }
  .box {
    border: 1px solid #000000;
    padding: 30px 0px;
    border-radius: 10px;
    margin-top: 20px;
  }
  .box p {
    font-size: 18px;
    font-weight: bold;
  }
  .home a {
    color: #000000!important;
  }

  .sidebar .nav .nav-item .nav-link {
    font-weight: 600;
  }

  .h-icon {
    font-size: 5rem;
    color: #FFC770;
  }

  .status-red {
    color: #FF0000;
  }

  .status-yellow {
    color: #FDDA0D;
  }

  .status-green {
    color: 	#008000;
  }

  .number-filter-input {
    width: 200px!important;
  }

  .react-photo-gallery--gallery img {
    width: 100%!important;
    height: 100%!important;
    border-radius: 0!important;
  }

  .borderTop {
    border-top: 1px solid gray;
  }

  .sidebar {
    position: fixed!important;
    overflow: auto!important;
    top: 70px;
    bottom: 0;
    padding-top: 20px;
  }

  .main-panel {
    margin-left: 260px!important;
    overflow: auto!important;
  }

  .page-body-wrapper.full-page-wrapper .main-panel {
    margin-left: 0px!important;
  }

  h3.title {
    width: 100%; 
    text-align: center; 
    border-bottom: 3px solid #000; 
    line-height: 0.1em;
    margin: 10px 0 20px; 
  } 

  h3.title span { 
      background: #fff; 
      padding: 5px 20px; 
      border: 3px solid black !important;
      border-radius: 1rem;
  }
 
  .custom-fieldset{
    border: 3px solid;
    border-radius: 1rem;
    padding: 2rem;
    padding-top: .5rem;
  }

  .custom-legend{ 
    width: auto;
    padding: 0 10px;
    text-align: center;
    position: relative;
  }

  .custom-input{
    height: 40px !important;  
    border-color: #000 !important;  
    border-radius: 1rem;
  }

  .sidebar .nav .nav-item .nav-link .menu-title {
    font-size: 14px!important;
  }

  .modal-45w{
    max-width: 45vw !important;
  }

  .modal-65w{
    max-width: 65vw !important;
  }

  .gp_image img{
    position: absolute;
    top: 2px;
    left: 35px;
    width: calc(165px - 35px);
  }

  .modal-70w{
    max-width: 70vw !important;
  }

  .ar_container{
    position: relative;
    top: 1.5rem;
    display: block;
    text-align: center;
  }
  .ar_container img{ 
    height: 120px;
    width: 170px;
  }

  .ar_left_container{
    position: absolute;
    top: 2rem;
    right: 2.7rem;
  }

  .ar_left_container label{
    text-align: right !important;
  }

  .text-right{
    text-align: right !important;
  }
  
  .border-invalid{
    border: 1px solid #dc3545; 
  }

  .select {
    background-color: #ffc770;
    border-color: #ffc770;
    padding: 0px 6px;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    font-weight: bold;
  }
  
  .select-alternate {
    background-color: #238F62;
    border-color: #238F62;
    padding: 0px 6px;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    font-weight: bold;
  }


  .collections-activeLink {
    border-bottom: 2px solid #FDBF;
    font-weight: bold !important;
  }
 
  .badge-carmax{
   background-color: #FDBF !important; 
  }

  .collections-activeLink p {
    color: #FDBF; 
  }
  
  .collection-badge-text{
    color: #000 !important;
  }

  .collections-navLink {
      font-weight: normal;
      padding-bottom: .2rem !important;
      margin-bottom: .5rem !important;
  }

  @media (min-width: 992px) {
    .sidebar-icon-only .main-panel {
      margin-left: 70px!important;
    }
  }
  
  @media only screen and (max-width: 600px) {
    button.mobile {
      display: block!important;
    }
    .dropdown>.desktop {
      display: none!important;
    }
    .content-wrapper {
      padding-left: 20px;
      padding-right: 20px;
    }
    .brand-logo-mini {
      margin-left: 22px;
    }
    .main-panel {
      margin-left: 0px!important;
    }
  }

  @media print { 
    @page {
      size: A4 !important; /* DIN A4 standard, Europe */
      margin: 10mm 0mm 20mm 0mm !important;
    }
  
    html,
    body {
      width: 210mm !important;
      height: 282mm !important;
      background: #fff !important;
      overflow: visible !important;
    }

    h3.title {
      width: 100%; 
      text-align: center; 
      border-bottom: 3px solid #000; 
      line-height: 0.1em;
      margin: 10px 0 20px; 
    } 

    h3.title span { 
        background: #fff; 
        padding: 5px 10px; 
        border: 3px solid black !important;
        border-radius: 1rem;
    }

    .custom-fieldset{
      border: 3px solid;
      border-radius: 1rem;
      padding: 2rem;
      padding-top: .5rem;
    }
  
    .custom-legend{ 
      width: auto;
      padding: 0 10px;
      text-align: center;
      position: relative;
    }
  
    .custom-input{
      height: 40px !important;  
      border-color: #000 !important;  
      border-radius: 1rem;
    }
  }
}
`
